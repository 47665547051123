import React from "react";
import deviceTrust from "../../assets/images/device_trust_icon.svg";
import planStore from "../../store/planStore";
import * as EN from "../../i18n/messages/en-US";
import * as DE from "../../i18n/messages/de-DE";
import { LOCALES } from "../../i18n";
import languageStore from "../../store/languageStore";
import translate from '../../i18n/translate';
import { getModifiedPrice, numberWithCommas } from '../../service/prod-config';

const PlanDetailsComponent = () => {
  const getText = keyName => {
    if (keyName) {
      const keyArr = keyName.split(".");
      if (languageStore.selectedLanguage === LOCALES.ENGLISH) {
        return EN[keyArr[0]][keyArr[1]]
      } else {
        return DE[keyArr[0]][keyArr[1]]
      }
    }
  }
  return (
    <div className="plan-wrapper">
      <div className="product-details-header">
        <div className="">
          <h2
            className="product-name"
            dangerouslySetInnerHTML={{
              __html: getText(planStore.productName)
            }}
          ></h2>
          <div className="collaboration">
            <div className="text">{translate('plan.collabration.header')}</div>
            <div className="carrier"></div>
          </div>
        </div>
        <div>
          <img src={deviceTrust} alt="" className="productImage" />
        </div>
      </div>
      <div className="product-details-body">
        <span className="product-details-description">
          <div className="product-description-header">{translate('plan.limit.header')}</div>
          {/* Add new key to plan */}
          <div className="plan-description-value">{numberWithCommas(planStore.deviceEndingRange)}{planStore.currencySymbol}</div>
        </span>
        <span className="product-details-description">
          <div className="product-description-header">{translate('plan.contract.header')}</div>
          <div className="product-description-value">
            {/* {planStore.productTenure} {planStore.productTenureType} */}1 Monat
          </div>
        </span>
        <span className="product-details-description">
          <div className="product-description-header">{translate('plan.payment.header')}</div>
          <div className="product-description-value">
            {/* {planStore.paymentDescription}  */}
            monatlich via 3Rechnung
          </div>
        </span>
      </div>
      <div className=" product-details-footer">
        <div className="product-price">{translate('plan.total.header')}</div>
        <div>
          <div className="product-price">
            { getModifiedPrice(planStore.actualProductPrice)}
            {planStore.currencySymbol}
          </div>
          <div className="product-tenure">{planStore.tenureToShow}</div>
        </div>
      </div>
    </div>
  )
}

export default PlanDetailsComponent
