import React from "react"
import ProgressIndicatorComponent from '../shared/progress-indicator.component';
import PlanDetailsComponent from '../shared/plan-details.component';
function FormLayoutComponent({ children, route }) {
  return <div className="container inner-layout-wrapper">
    <div className="row">
      <div className="col-md-2">
        <ProgressIndicatorComponent route={route} />
      </div>
      <div className="col-md-6">
        {children}
      </div>
      <div className="col-md-4">
        <PlanDetailsComponent />
      </div>
    </div>
  </div>
}

export default FormLayoutComponent
